import React from "react"
import thnkdev_logo from "../../images/thnkdev.png"
import textla_logo from "../../images/textla_logo.png"
import quickres_icon from "../../images/quickres_icon.png"

import screenie_icon_webp from "../../images/screenie_icon.webp";
import screenie_icon_png from "../../images/screenie_icon.png";

import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap"

function HeroLogo(props) {
  const pageName = props.pageInfo.pageName

  let heroLogo = thnkdev_logo
  let heroLogoWebp = thnkdev_logo
  let homeLink = pageName

  let className = "d-inline-block align-top"
  let text = ''
  let height = 50

  if (pageName === "thnkdev" || pageName === "textla") {
    homeLink = '/'
    height = 36
  }

  if (pageName === "screenie") {
    heroLogo = screenie_icon_png
    heroLogoWebp = screenie_icon_webp
    homeLink = '/Screenie'
    className = " animated bounceIn"
    text = 'Screenie'
  }

  return (
    <Navbar.Brand href={homeLink}>
      <picture className={className}>
        <source srcSet={heroLogoWebp} type="image/webp" />
        <source srcSet={heroLogo} type="image/png" />
        <img height={height} src={heroLogo} />
      </picture>
      <span
        className="screenie_logo_text">
        {text}
      </span>
    </Navbar.Brand>
  )
}

export default HeroLogo
