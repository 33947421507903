import React from "react"
import { Link } from "gatsby"
import HeroLogo from "./heroLogo"
import LeftNavLinks from "./leftNavLinks"
import RightNavLinks from "./rightNavLinks"

import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  let variant = "light";
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg={variant} variant={variant}>
        <HeroLogo pageInfo={pageInfo} />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className={pageInfo.pageName}>
          <LeftNavLinks pageInfo={pageInfo} />
          <RightNavLinks pageInfo={pageInfo} />
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
