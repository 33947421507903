import React from "react"
import { Link } from "gatsby"
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap"

import {
  QuickResLink,
  ScreenieLink,
  TextlaLink,
  PearlLink,
  QuickParkLink,
  ThnkDevLink,
} from "./links"

function ThnkDevRightNavLinks() {
  return (
    <Nav>
      <QuickResLink />
      <ScreenieLink />
      <PearlLink />
      <QuickParkLink />
    </Nav>
  )
}

function TextlaRightNavLinks() {
  return (
    <Nav>
      <ThnkDevLink />
    </Nav>
  )
}

function ScreenieRightNavLinks() {
  return (
    <Nav>
      <QuickResLink />
      <PearlLink />
      <QuickParkLink />
    </Nav>
  )
}

function RightNavLinks(props) {
  const pageName = props.pageInfo.pageName

  let rightNavLinks = <ThnkDevRightNavLinks />

  if (pageName === "screenie") {
      rightNavLinks = <ScreenieRightNavLinks />
  }

  return rightNavLinks
}

export default RightNavLinks
