import React from "react"
import { Link } from "gatsby"
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap"

import { AboutUsLink } from "./links"

function ThnkDevLeftNavLinks() {
  return (
    <Nav className="mr-auto">
      <AboutUsLink />

    </Nav>
  )
}

function TextlaLeftNavLinks() {
  return (
    <Nav className="mr-auto">
      <AboutUsLink />
    </Nav>
  )
}

function ScreenieLeftNavLinks() {
  return (
    <Nav className="mr-auto">
      <Nav.Link href="/Screenie/pressKit">Press Kit</Nav.Link>
    </Nav>
  )
}

function LeftNavLinks(props) {
  const pageName = props.pageInfo.pageName

  let leftNavLinks = <ThnkDevLeftNavLinks />

  if (pageName === "screenie") {
    leftNavLinks = <ScreenieLeftNavLinks />
  }

  return leftNavLinks
}

export default LeftNavLinks
