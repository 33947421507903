import React from "react"
import { Link } from "gatsby"
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBolt,
  faCamera,
  faComments,
  faCircle,
  faParking,
} from "@fortawesome/free-solid-svg-icons"

FontAwesomeIcon.config = { autoAddCss: false }

function QuickResLink() {
  return (
    <Nav.Link href="https://www.thnkdev.com/QuickRes/">
      <FontAwesomeIcon icon={faBolt} className="navbar-icons" />
      QuickRes
    </Nav.Link>
  )
}

function ScreenieLink() {
  return (
    <Nav.Link href="https://www.thnkdev.com/Screenie">
      <FontAwesomeIcon icon={faCamera} className="navbar-icons" />
      Screenie
    </Nav.Link>
  )
}

function TextlaLink() {
  return (
    <Nav.Link href="textla">
      <FontAwesomeIcon icon={faComments} className="navbar-icons" />
      Textla
    </Nav.Link>
  )
}

function PearlLink() {
  return (
    <Nav.Link href="https://www.thnkdev.com/Pearl">
      <FontAwesomeIcon icon={faCircle} className="navbar-icons" />
      Pearl
    </Nav.Link>
  )
}

function QuickParkLink() {
  return (
    <Nav.Link href="https://www.thnkdev.com/QuickPark">
      <FontAwesomeIcon icon={faParking} className="navbar-icons" />
      QuickPark
    </Nav.Link>
  )
}

function ThnkDevLink() {
  return <Nav.Link href="https://www.thnkdev.com">ThnkDev</Nav.Link>
}

function AboutUsLink() {
  return <Nav.Link href="/About">About us</Nav.Link>
}

export {
  QuickResLink,
  ScreenieLink,
  TextlaLink,
  PearlLink,
  QuickParkLink,
  ThnkDevLink,
  AboutUsLink,
}
